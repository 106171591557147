import React, { createRef, FC, useCallback, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
import Slider from 'react-slick';

import iconDown from 'public/images/v2-icon-down-charcoal.svg';

import EscapodApi from 'clients/Escapod';
import Klaviyo from 'clients/Klaviyo/browser';
import * as Gtag from 'clients/Gtag';
import Fbq from 'clients/Fbq';

import { BlockWrapper, Button, Image, TextField } from 'components';
import { Block, Image as TImage } from 'types';
import { PopupCustomer } from 'contexts/ChatPopupContext';
import formatMoney from 'utils/formatMoney';

export type TInventoryHero = Block<
  'inventoryHero',
  {
    title: string;
    price: number;
    compareAt: number;
    features: string[];
    specs: { title: string; value: string }[];
    images: TImage[];
    description: string;
    buttonAction: 'contact';
  }
>;

const InventoryHero: FC<TInventoryHero> = ({
  title,
  price = 0,
  compareAt = 0,
  features,
  specs,
  images,
  description,
  buttonAction = 'contact'
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const inputRef = createRef<HTMLInputElement | HTMLTextAreaElement>();
  const sliderRef = useRef<null | Slider>(null);
  const [customer, setCustomer] = useState<PopupCustomer>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    message: ''
  });

  const _setSlide = useCallback(
    (index: number) => {
      sliderRef.current?.slickGoTo(index);
    },
    [sliderRef]
  );

  const _submit = useCallback(() => {
    if (!customer.message) return inputRef.current?.focus();

    setIsLoading(true);

    const createContact = async () => {
      const response = await EscapodApi.contact.create({
        templateParams: {
          FIRST_NAME: customer.firstName,
          LAST_NAME: customer.lastName,
          EMAIL: customer.email,
          PHONE: customer.phone,
          SUBJECT: 'sales',
          MESSAGE: customer.message
        }
      });

      if (response.message === 'ok') {
        setTimeout(() => {
          setIsLoading(false);
          setIsSent(true);
          setCustomer({ ...customer, message: '' });
        }, 3000);
      } else {
        setIsLoading(false);
      }
    };

    Fbq('Contact');

    Gtag.event('contact_form_submission', {
      path: window?.location.pathname
    });
    Gtag.event('conversion', {
      send_to: 'AW-814232277/hlCNCP2unakYENXloIQD',
      event_callback: () => {
        return;
      }
    });

    Klaviyo.events.contact({
      name: `${customer.firstName} ${customer.lastName}`,
      phone: customer.phone,
      subject: 'sales',
      message: customer.message
    });

    createContact();
  }, [customer, inputRef]);

  return (
    <BlockWrapper className="InventoryHero">
      <div className="InventoryHero__inner flex flex-wrap">
        <div className="InventoryHero__images w-full lg:w-1/2 lg:pr-12 flex flex-col mb-8 lg:mb-0">
          <Slider
            ref={sliderRef}
            adaptiveHeight={true}
            arrows={false}
            className="InventoryHero__slider"
          >
            {images.map(image => (
              <div key={`InventoryHero__image-${image._key}`} className="InventoryHero__slide">
                <div
                  className="InventoryHero__image relative w-full"
                  style={{
                    paddingBottom: `${
                      ((image.metadata?.dimensions.height || 1) /
                        (image.metadata?.dimensions.width || 1)) *
                      100
                    }%`
                  }}
                >
                  <Image className="w-full" layout="fill" src={image.src} alt={image.alt} />
                </div>
              </div>
            ))}
          </Slider>
          <div className="InventoryHero__thumbnails grid grid-cols-6 lg:grid-cols-5 gap-2 lg:gap-3 mt-1">
            {images.map((image, i) => (
              <Button
                key={`InventoryHero__thumbnail-${image._key}`}
                variant="no-style"
                className="relative w-full pb-[100%] mr-3"
                onClick={() => _setSlide(i)}
              >
                <Image objectFit="cover" layout="fill" src={image.src} alt={image.alt} />
              </Button>
            ))}
          </div>
          {specs && (
            <div className="InventoryHero__specs mt-8 hidden lg:flex w-full">
              <ul className="grid grid-cols-2 gap-x-6 w-full">
                {specs.map(spec => (
                  <li
                    key={`Spec: ${spec.title}`}
                    className="border-t-1 border-stone-200 py-2 flex justify-between items-center"
                  >
                    <span className="text-stone-400 font-grotesk-headline text-xs">
                      {spec.title}
                    </span>
                    <span className="font-grotesk-sub-headline text-xs">{spec.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="InventoryHero__info w-full lg:w-1/2">
          <h1 className="font-grotesk-headline-news text-2xl lg:text-3xl mb-6">{title}</h1>
          {!!compareAt ? (
            <span className="font-grotesk-sub-headline-news text-xl block mb-8">
              <span className="text-stone-400 line-through mr-2">${formatMoney(price)}</span>
              <span className="text-fire">${formatMoney(compareAt)}</span>
            </span>
          ) : (
            <span className="font-grotesk-sub-headline-news text-xl block mb-8">
              ${formatMoney(price)}
            </span>
          )}
          <p className="mb-8">{description}</p>
          {!!features.length && (
            <div className="InventoryHero__features mb-8">
              <ul className="pl-8">
                {features.map(feature => (
                  <li key={`feature-${feature}`} className="list-disc">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {specs && (
            <div className="InventoryHero__specs mb-6 lg:hidden">
              <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
                {specs.map(spec => (
                  <li
                    key={`mobile spec: ${spec.title}`}
                    className="border-t-1 border-stone-200 py-2 flex justify-between items-center"
                  >
                    <span className="text-stone-400 font-grotesk-headline text-xs">
                      {spec.title}
                    </span>
                    <span className="font-grotesk-sub-headline text-xs">{spec.value}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {buttonAction === 'contact' && (
            <div className="InventoryHero__contact-form bg-stone-100 rounded-sm p-4">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                <TextField name="fname" type="text" ariaLabel="First Name" label="First Name" />
                <TextField name="lname" type="text" ariaLabel="Last Name" label="Last Name" />
                <TextField name="email" type="text" ariaLabel="Email" label="Email" />
                <TextField name="phone" type="text" ariaLabel="Phone" label="Phone" />
              </div>
              <div className="mt-4">
                <div className="flex justify-center items-center mb-4">
                  <div className="border-t-1 border-stone-400 w-1/2" />
                  <div className="px-2 flex justify-center">
                    <Button
                      variant="no-style"
                      className="text-xs flex items-center underline relative text-stone-500"
                      onClick={() => setFormIsOpen(!formIsOpen)}
                    >
                      <span>Ask A Question</span>
                      <div className="relative w-3 ml-1 pt-1">
                        <Image
                          width="12px"
                          className={cx('!transition-all', { 'rotate-180': !formIsOpen })}
                          src={iconDown}
                          alt="Down Menu Icon"
                        />
                      </div>
                    </Button>
                  </div>
                  <div className="border-t-1 border-stone-400 w-1/2" />
                </div>
                <AnimateHeight height={formIsOpen ? 'auto' : 0}>
                  <div className="w-full pb-4">
                    <TextField name="message" type="textarea" ariaLabel="Message" label="Message" />
                  </div>
                </AnimateHeight>
                <div className="flex justify-between items-center">
                  <Button disabled={isLoading || isSent} color="sand" onClick={_submit}>
                    Request More Info
                  </Button>
                  <div className="flex flex-col text-stone-500 underline text-[9px] lg:text-[11px] items-end text-right">
                    <a className="mb-1" href="tel:4355038191">{`(435) - 503 - 8191`}</a>
                    <a
                      href="https://www.google.com/maps/dir//escapod+trailers/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x87521595acdecea9:0x22aae729e837ab59?sa=X&ved=1t:3061&ictx=111"
                      target="blank"
                    >
                      <span>{`60 S 50 W, Coalville, UT 84017`}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isSent && <span>Your information request was received. We will respond shortly!</span>}
        </div>
      </div>
    </BlockWrapper>
  );
};

export default InventoryHero;
